import { getRandomHex } from '@xy/core'
import { getUUID } from '@/utils/strUtil'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import router from '@/router'
import { base64Encode } from '@/utils/strUtil'
export function getIP(callback) {
  window.$.ajax({
    type: 'get',
    // url: 'https://ipv4.icanhazip.com/',
    url: 'https://geo.xiwshijieheping.com/ip/area',
    success: function (data) {
      console.log(data)
      const { ip } = data
      callback(ip.replace(/\r|\n/gi, ''), data.area)
    },
    error: function (err) {
      callback('')
    }
  })
}
function ajax(options) {
  var xhr = new XMLHttpRequest()
  var data = options.data,
    url = options.url,
    method = options.method
  if (data && 'string' != typeof data) {
    data = JSON.stringify(data)
  }
  if (method != 'POST' && data) {
    url = url + (url.indexOf('?') > -1 ? '&' : '?') + data
    data = null
  }
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4) {
      if (xhr.status == 200) {
        var res = xhr.response || xhr.responseText || {}
        options.success && options.success(typeof res == 'string' ? JSON.parse(res) : res)
      } else {
        options.error && options.error(xhr, xhr.statusText)
      }
      options.complete && options.complete(xhr)
    }
  }
  xhr.ontimeout = function () {
    options.error && options.error(xhr, xhr.statusText)
  }

  try {
    xhr.open(method, url, options.async !== false)
    xhr.withCredentials = true
    try {
      if (xhr.setRequestHeader && options.contentType) {
        xhr.setRequestHeader('Content-Type', options.contentType)
      }
      if (options.timeout) {
        xhr.timeout = options.timeout
      }
    } catch (e1) {}
    xhr.send(data || null)
  } catch (e2) {}
  return xhr
}
// 进入落地页上报
export function sessionReport(param) {
  let uuid = localStorage.getItem('KEY_UUID')
  if (!uuid) {
    uuid = getRandomHex(16)
    localStorage.setItem('KEY_UUID', uuid)
  }
  const data = {
    type: 'page_share_success',
    inviteCode: deviceInfo.invitationCode,
    deviceInfo: {
      uuid: uuid
    },
    appInfo: {
      appId: deviceInfo.appId,
      packageCode: deviceInfo.packageCode
    },
    terminalInfo: {
      terminal: deviceInfo.isiOS ? 1 : deviceInfo.isAndroid ? 2 : 4,
      ip: param.ip
      // networkEnv: navigator.connection.effectiveType,
    }
  }
  window.$.ajax({
    type: 'post',
    url: '/api/open/app/user_session/report',
    data: JSON.stringify({ data }),
    contentType: 'application/json;charset=utf-8',
    headers: {
      Authorization: 'M2ZoCmm/GT12Q5ecOsxefz35aqT02+ZlzSawhaf5D5Q=',
      'X-Client-Identity': 'APP/1;com.smarter.app;1.0.0;' + deviceInfo.packageCode + ' SDI/D4843E87-6CB4-4B4F-82C7-6A6DD02C5A84;0 SYS/IOS;12 FM/Apple;iPhone10 NE/WIFI LANG/zh-CN',
      'X-Client-TimeStamp': new Date().getTime()
    },
    success: function (res) {
      console.log(res)
    }
  })
}
export async function addLiquidId(data, callback = () => {}) {
  const fpPromise = FingerprintJS.load();
  const datas = await fpPromise.then(fp =>fp.get())
  const components = {}
  Object.entries(datas.components).forEach(([key, value]) => {
    if(!['screenResolution','fonts','dateTimeLocale','canvas'].includes(key)){
      const newV = {...value}
      if(newV.duration !== 0){
        newV.duration = 1
      }
      components[key] = newV
    }
  });
  const visitorId = FingerprintJS.hashComponents(components)
  data.uuid = visitorId
  // localStorage.setItem('uuid', res.visitorId)
  let timing = new Date().getTime()
  window.$.ajax({
    type: 'post',
    url: `/api/open/user_c/inviteRelation/add`,
    data: JSON.stringify({ data }),
    // dataType: 'json',
    contentType: 'application/json;charset=utf-8',
    headers: {
      Authorization: 'M2ZoCmm/GT12Q5ecOsxefz35aqT02+ZlzSawhaf5D5Q=',
      'X-Client-Identity': 'APP/1;com.smarter.app;1.0.0;' + deviceInfo.packageCode + ' SDI/D4843E87-6CB4-4B4F-82C7-6A6DD02C5A84;0 SYS/IOS;12 FM/Apple;iPhone10 NE/WIFI LANG/zh-CN',
      'X-Client-TimeStamp': timing,
      "X-Olient-Origin": base64Encode(base64Encode(timing)),
    },
    success: function (res) {
      console.log('addLiquidId==', res)
      if (res.data) {
        localStorage.setItem('beInvitedUuid', res.data.beInvitedUuid)
        // 执行回调（配置图片域名数据）
        res.data.appId && callback(res.data.appId)
      }
    }
  })
    // const fpPromise = FingerprintJS.load()
    // fpPromise
    //   .then((fp) => fp.get())
    //   .then((res) => {
    //     data.uuid = res.visitorId
    //     localStorage.setItem('uuid', res.visitorId)
    //     window.$.ajax({
    //       type: 'post',
    //       url: `/api/open/user_c/inviteRelation/add`,
    //       data: JSON.stringify({ data }),
    //       // dataType: 'json',
    //       contentType: 'application/json;charset=utf-8',
    //       headers: {
    //         Authorization: 'M2ZoCmm/GT12Q5ecOsxefz35aqT02+ZlzSawhaf5D5Q=',
    //         'X-Client-Identity': 'APP/1;com.smarter.app;1.0.0;' + deviceInfo.packageCode + ' SDI/D4843E87-6CB4-4B4F-82C7-6A6DD02C5A84;0 SYS/IOS;12 FM/Apple;iPhone10 NE/WIFI LANG/zh-CN',
    //         'X-Client-TimeStamp': new Date().getTime()
    //       },
    //       success: function (res) {
    //         console.log('addLiquidId==', res)
    //         if (res.data) {
    //           localStorage.setItem('beInvitedUuid', res.data.beInvitedUuid)
    //           // 执行回调（配置图片域名数据）
    //           res.data.appId && callback(res.data.appId)
    //         }
    //       }
    //     })
    //   })
}
export function isValidBrowser() {
  var ua = navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger\/[0-9]/i)) {
    // return 'weixin'
    return false
  }

  if (ua.match(/WeiBo/i) == 'weibo') {
    //在新浪微博客户端打开
    // $('.from').html("微博");
    return false
  }

  if (ua.match(/QQ\/[0-9]/i)) {
    // return 'QQ'
    return false
  }
  return true
}
// 新增上报接口
export async function clickReport(data, callback = () => {}) {

  const fpPromise = FingerprintJS.load();
  const datas = await fpPromise.then(fp =>fp.get())
  const components = {}
  Object.entries(datas.components).forEach(([key, value]) => {
    if(!['screenResolution','fonts','dateTimeLocale','canvas'].includes(key)){
      const newV = {...value}
      if(newV.duration !== 0){
        newV.duration = 1
      }
      components[key] = newV
    }
  });
  const visitorId = FingerprintJS.hashComponents(components)
  data.uuid = visitorId
  let timing = new Date().getTime()
  window.$.ajax({
    type: 'post',
    url: `/api/open/user_c/inviteRelation/clickReport`,
    data: JSON.stringify({ data }),
    timeout: 8000,
    // dataType: 'json',
    contentType: 'application/json;charset=utf-8',
    headers: {
      clickid: router.currentRoute.query.clickid,
      Authorization: 'M2ZoCmm/GT12Q5ecOsxefz35aqT02+ZlzSawhaf5D5Q=',
      'X-Client-Identity': 'APP/1;com.smarter.app;1.0.0;' + deviceInfo.packageCode + ' SDI/D4843E87-6CB4-4B4F-82C7-6A6DD02C5A84;0 SYS/IOS;12 FM/Apple;iPhone10 NE/WIFI LANG/zh-CN',
      'X-Client-TimeStamp': timing,
      "X-Olient-Origin": base64Encode(base64Encode(timing)),
    },
    success: function (res) {
      console.log('clickReport==', res)
      callback('reportSuccess')
    },
    error: function (err) {
      console.log('err：', err.statusText)
      callback(err)
    }
  })
}
