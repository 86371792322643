import request from '@/api/request'

// 从渠道包获取信息
export function queryDownloadInfo(data) {
  return request({
    url: '/api/open/applet/landing/queryDownloadInfo',
    method: 'post',
    data
  })
}
