import { v4 as uuidv4} from 'uuid'

/** 数字ascii码 */
const CODE_NUMBER = [48, 57]
/** 大写字母ascii码 */
const CODE_LETTER_UP = [65, 90]
/** 小写字母ascii码 */
const CODE_LETTER_LOW = [97, 122]

// 空字符替代占位符
export function getStrDefaultLine(str) {
  return (str == undefined || str == null || str === '') ? '--' : str
}


/**
 * 保留小数点后几位 返回值类型string
 * @param {number} value 值
 * @param {number} digits 保留位数[0到8之间] 默认不保留
 * @param {boolean} is_round 是否四舍五入
 * @return {string} 返回值
 */
export function toFixed(value, digits, is_round = true) {
  if (!value || digits > 8) return value
  if (digits < 0) {
    digits = 0
  }
  // 计算截取后的值
  const length = Math.pow(10, digits)
  const new_value = is_round ? Math.round(value * length) / length : Math.floor(value * length) / length
  // 小数位不够自动补0
  return new_value.toFixed(digits)
}

/**
 * 取len长度的随机数字和大小写字母组合的字符串
 * @param len
 */
export function getRandomString(len) {
  const arr_info = [CODE_NUMBER, CODE_LETTER_UP, CODE_LETTER_LOW]
  return _getRandomSringByArray(arr_info, len)
}

/**
 * 通过二维数组取随机字符
 * @param arr_info
 * @param len
 */
function _getRandomSringByArray(arr_info, len) {
  let ret = ''
  for (let i = 0; i < len; ++i) {
    const interval = arr_info[Math.floor(Math.random() * arr_info.length)]
    ret += _getRandomSringByInterval(interval[0], interval[1])
  }
  return ret
}

/**
 *
 * @param min
 * @param max
 */
function _getRandomSringByInterval(min, max) {
  return String.fromCharCode(min + Math.floor(Math.random() * (max - min + 1)))
}

/**
 *
 * base64加密
 */
export function base64Encode(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode('0x' + p1)));
}

export function getUUID() {
  let uuid= localStorage.getItem('uuid')
  return uuid
}