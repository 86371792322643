import { rsaEncryptByPubkey, aesEncryptReplaceBlank, aesDecrypt } from '@xy/core'
// import JSEncrypt from 'jsencrypt'
/** 采用sdk加密 */
const useEncrypt = process.env.NODE_ENV !== 'development'
// const useEncrypt = true
/**
 * api参数加密
 * @param data 需要加密的数据
 * @param params :{key,url}
 * @returns
 */
export function encryptData(data, params = {}) {
  if (!useEncrypt) return JSON.stringify(data)
  let str_data

  str_data = JSON.stringify(data)
  
  const key = params.key
  const en_data = aesEncryptReplaceBlank(key, str_data)
  if (!en_data) return
  const en_key = rsaEncryptByPubkey(getPbkey(), key)
  // const en_key = rsaEncrypt(getPbkey(), key)
  const r_data = {
    data: en_data,
    key: en_key
  }
  // console.log('加密参数---',en_key, ',', en_data, ',', key );
  return r_data
}

/**
 * api返回数据解密
 * @param data 需要解密的数据
 * @param params :{key,url}
 */
export function decryptData(data, params = {}) {
  if (!useEncrypt) return data
  // if (useSdkEncrypt && window.wx) {
  //   return sdkDecrypt(data,params.url)
  // } else {
  const data_str = aesDecrypt(params.key, data)
  let data_json
  try {
    data_json = JSON.parse(data_str)
  } catch (error) {
    return data_str
  }
  return data_json
  // }
}

// /////////////////////////////////辅助函数////////////////////////////////////////
var _pbkey = undefined
// 获取公钥
function getPbkey() {
  if (!_pbkey) {
    // _pbkey = process.env.PUBLIC_KEY
    _pbkey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC03Ia/id+UqxK126h+luGshAP4oL2guu5wZCSNGuNbPMATuOd5/8/YcLDukDz5LtxdabH+tsX4JX5vM2Wl6ty4eyiWIpw89a0nBL4firSgHe0w2iZ2FCz2XnGhnwNDTlAdUi2cghzjMHOGJt49/ZoUqi3G/8O6QlIqCYwEJg5a4QIDAQAB'
    // Log.log("rsa 公钥",_pbkey)
  }
  return _pbkey
}

// rsa加密处理
// function rsaEncrypt(pubKey,txt) {
//   let rsaPassWord = ''
//   let encryptor = new JSEncrypt()  // 创建加密对象实例
//   //之前生成的公钥，复制的时候要小心不要有空格(此处把密钥省略了，自己写的时候可把自己生成的公钥粘到对应位置)
//   encryptor.setPublicKey(pubKey)//设置公钥
//   rsaPassWord = encryptor.encrypt(txt) 
//   return rsaPassWord
// } 